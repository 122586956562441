.field {
  margin: 30px 0;
}

.label {
  display: block;
  font-weight: 500;
  margin-bottom: 5px;
}

.error {
  color: red;
}
