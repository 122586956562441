.logo {
  width: 75%;
  display: block;
  margin: 0 auto 30px;
}

.nav {
  height: 100%;
  display: grid;
  gap: 5px;
  grid-template-columns: 55px auto;
  background-color: var(--branded-blue);
  border-radius: 0 0 15px 0;
}

.mainMenu {
  /* background-color: rgba(255, 255, 255, 0.025); */
  align-items: center;
  color: white;
  padding: 15px 0;
}

.mainMenu a {
  color: white;
  text-decoration: none;
}

.mainMenu ul {
  display: flex;
  padding: 0;
  margin: 0;
  flex-direction: column;
  gap: 15px;
  border-top: 1px solid rgba(255, 255, 255, 0.05);
  padding: 15px 0;
}

.mainMenu ul li {
  display: block;
  font-size: 13px;
  padding: 0 10px;
}

.secondaryMenu {
  padding-top: 15px;
  background: rgba(0, 0, 0, 0.3);
}

.secondaryMenu ul {
  display: flex;
  padding: 0;
  margin: 0;
  flex-direction: column;
  gap: 10px;
}

.secondaryMenu ul li {
  display: flex;
  font-size: 10px;
  flex-direction: column;
  align-items: center;
}

.secondaryMenu button {
  font-size: 10px;
  background: none;
  border: none;
  color: white;
  cursor: pointer;
}

.secondaryMenu span {
  color: white;
}
