header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 0 0 0 15px;
  padding: 0 30px;
  font-size: 14px;
}

header ul {
  display: flex;
  gap: 15px;
}

header ul li {
  display: block;
  color: rgba(0, 0, 0, 0.3);
}
