.layout {
  height: 100vh;
}

.session {
  display: flex;
  justify-content: center;
  align-items: center;
}

.session-logo {
  height: 40vh;
  background-color: var(--branded-blue);
  overflow: hidden;
}

.session-form {
  padding-top: 45px;
  flex-direction: column;
}

.layout form {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 30px;
}

.layout h1 {
  margin: 0;
}

.layout .input-login-field label {
  font-size: 18px;
  padding: 14px;
  position: absolute;
  left: 1px;
  pointer-events: none;
  transition: 0.3s ease all;
  color: rgb(193, 190, 190);
}

.layout .input-login-field {
  position: relative;
  width: auto;
  height: auto;
}

.layout input {
  height: 50px;
  width: 350px;
}

.layout input:focus + label,
.layout input:valid + label {
  /* color: white; */
  font-size: 12px;
  top: 2px;
  left: 5px;
  padding: 0 5px 0 5px;
}

.layout button {
  height: 50px;
  width: 300px;
  display: flex;
  justify-content: center;
  transition: 0.3s ease all;
}

.layout img {
  max-width: 420px;
}

.layout button:hover {
  background: #4f46e5;
  color: #ffffff;
  border-color: #4f46e5;
  font-size: 15px;
}
