header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 0 0 0 15px;
  padding: 0 30px;
  font-size: 14px;
}

header ul {
  display: flex;
  grid-gap: 15px;
  gap: 15px;
}

header ul li {
  display: block;
  color: rgba(0, 0, 0, 0.3);
}

.NavMain_logo__3h_6e {
  width: 75%;
  display: block;
  margin: 0 auto 30px;
}

.NavMain_nav__2vqHq {
  height: 100%;
  display: grid;
  grid-gap: 5px;
  gap: 5px;
  grid-template-columns: 55px auto;
  background-color: var(--branded-blue);
  border-radius: 0 0 15px 0;
}

.NavMain_mainMenu__36NqC {
  /* background-color: rgba(255, 255, 255, 0.025); */
  align-items: center;
  color: white;
  padding: 15px 0;
}

.NavMain_mainMenu__36NqC a {
  color: white;
  text-decoration: none;
}

.NavMain_mainMenu__36NqC ul {
  display: flex;
  padding: 0;
  margin: 0;
  flex-direction: column;
  grid-gap: 15px;
  gap: 15px;
  border-top: 1px solid rgba(255, 255, 255, 0.05);
  padding: 15px 0;
}

.NavMain_mainMenu__36NqC ul li {
  display: block;
  font-size: 13px;
  padding: 0 10px;
}

.NavMain_secondaryMenu__1TJ6H {
  padding-top: 15px;
  background: rgba(0, 0, 0, 0.3);
}

.NavMain_secondaryMenu__1TJ6H ul {
  display: flex;
  padding: 0;
  margin: 0;
  flex-direction: column;
  grid-gap: 10px;
  gap: 10px;
}

.NavMain_secondaryMenu__1TJ6H ul li {
  display: flex;
  font-size: 10px;
  flex-direction: column;
  align-items: center;
}

.NavMain_secondaryMenu__1TJ6H button {
  font-size: 10px;
  background: none;
  border: none;
  color: white;
  cursor: pointer;
}

.NavMain_secondaryMenu__1TJ6H span {
  color: white;
}

.Home_layout__2Bez1 {
  display: grid;
  grid-gap: 30px;
  gap: 30px;
  grid-template-columns: 440px auto;
}


.layout {
  height: 100vh;
}

.session {
  display: flex;
  justify-content: center;
  align-items: center;
}

.session-logo {
  height: 40vh;
  background-color: var(--branded-blue);
  overflow: hidden;
}

.session-form {
  padding-top: 45px;
  flex-direction: column;
}

.layout form {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  grid-gap: 30px;
  gap: 30px;
}

.layout h1 {
  margin: 0;
}

.layout .input-login-field label {
  font-size: 18px;
  padding: 14px;
  position: absolute;
  left: 1px;
  pointer-events: none;
  transition: 0.3s ease all;
  color: rgb(193, 190, 190);
}

.layout .input-login-field {
  position: relative;
  width: auto;
  height: auto;
}

.layout input {
  height: 50px;
  width: 350px;
}

.layout input:focus + label,
.layout input:valid + label {
  /* color: white; */
  font-size: 12px;
  top: 2px;
  left: 5px;
  padding: 0 5px 0 5px;
}

.layout button {
  height: 50px;
  width: 300px;
  display: flex;
  justify-content: center;
  transition: 0.3s ease all;
}

.layout img {
  max-width: 420px;
}

.layout button:hover {
  background: #4f46e5;
  color: #ffffff;
  border-color: #4f46e5;
  font-size: 15px;
}

.Form_field__14tv- {
  margin: 30px 0;
}

.Form_label__2WC5F {
  display: block;
  font-weight: 500;
  margin-bottom: 5px;
}

.Form_error__x_GpY {
  color: red;
}

.Loader_wrapperSm__1zsGP {
  width: 100%;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Loader_wrapperMed__1R73l {
  width: 100%;
  min-height: 20vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Loader_wrapperFull__-7nr_ {
  width: 100vw;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

@font-face {
  font-family: "Fira Sans";
  src: url(/static/media/calibri-regular.baf2d27a.ttf),
    url(/static/media/calibri-italic.5e5c563c.ttf),
    url(/static/media/calibri-bold-italic.a3e6f734.ttf),
    url(/static/media/calibri-bold.8879a4ca.ttf);
}

:root {
  --branded-blue: #224057;
  --branded-yellow: #f8c762;
}

html,
body {
  padding: 0;
  margin: 0;
  background-color: #f3f3f3;
  font-family: "Calibri", sans-serif;
  color: black;
}

* {
  box-sizing: border-box;
}

.theme-wrapper {
  display: grid;
  grid-gap: 15px;
  gap: 15px;
  height: 100vh;
  grid-template-columns: 0 auto;
  grid-template-rows: 45px auto 30px;
  grid-template-areas:
    "nav-main header"
    "nav-main body-main"
    "footer-main footer-main";
}

.theme-wrapper.expand {
  grid-template-columns: 280px auto;
}

header {
  grid-area: header;
}

.nav-main {
  grid-area: nav-main;
}

.body-main {
  grid-area: body-main;
  margin-right: 45px;
}

.footer-main {
  grid-area: footer-main;
  display: flex;
  align-items: center;
  color: white;
  background-color: #224057;
  background-color: var(--branded-blue);
  font-size: small;
  justify-content: center;
}

p {
  font-size: 14px;
  line-height: 1.3;
}

a {
  color: #224057;
  color: var(--branded-blue);
  text-decoration: underline;
}

.box {
  background: #ffffff;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  padding: 15px;
}

.btn {
  display: inline-flex;
  padding: 5px;
  margin-bottom: 10px;
  background: lightblue;
}

.btn-secondary {
  background-color: #f8c762 !important;
  background-color: var(--branded-yellow) !important;
  border-color: #f8c762 !important;
  border-color: var(--branded-yellow) !important;
  color: black !important;
}

.btn-secondary:hover {
  background-color: #e3b451 !important;
}

.p-component {
  font-family: "Calibri", sans-serif;
}
.p-button {
  background-color: #315a79;
  border-color: #315a79;
}

.p-progressbar .p-progressbar-value {
  background-color: var(--branded-blue);
}

.p-button.p-button-outlined {
  color: #315a79;
  border-color: #315a79;
}
.p-button:enabled:hover {
  background-color: var(--branded-blue);
  border-color: var(--branded-blue);
}

.p-button.p-button-outlined:enabled:hover {
  color: #315a79;
  border-color: #315a79;
}

.p-fieldset.p-fieldset-toggleable .p-fieldset-legend a {
  font-size: small;
  padding: 10px 30px;
}

