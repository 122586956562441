.p-component {
  font-family: "Calibri", sans-serif;
}
.p-button {
  background-color: #315a79;
  border-color: #315a79;
}

.p-progressbar .p-progressbar-value {
  background-color: var(--branded-blue);
}

.p-button.p-button-outlined {
  color: #315a79;
  border-color: #315a79;
}
.p-button:enabled:hover {
  background-color: var(--branded-blue);
  border-color: var(--branded-blue);
}

.p-button.p-button-outlined:enabled:hover {
  color: #315a79;
  border-color: #315a79;
}

.p-fieldset.p-fieldset-toggleable .p-fieldset-legend a {
  font-size: small;
  padding: 10px 30px;
}
